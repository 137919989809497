export default {
	environment: "production",
	protocol: "https",
	domain: "kouzoglouinsulations.gr",
	url: "https://kouzoglouinsulations.gr",
	API: {
		url: "https://api.kouzoglouinsulations.gr/v1"
	},
	email: {
		contact: "info@kouzoglouinsulations.gr",
		administrator: "administrator@kouzoglouinsulations.gr"
	},
	google: {
		tagManager: {
			key: "GTM-WPN2WXS"
		},
		maps: {
			key: "AIzaSyBksS3Y7Jo185Y7oAIQx-G4fkKg57VFjhE"
		}
	}
}
